/* eslint-disable no-case-declarations */
import { Localization } from 'constant/config';
import { FC } from 'react';
import AuthTemplate from 'themes/AuthTemplate';
import CustomerTemplate from 'themes/CustomerTemplate';
import DealerTemplate from 'themes/DealerTemplate';
import DashboardTemplate from 'themes/DashboardTemplate';
import SuperuserTemplate from 'themes/SuperuserTemplate';

interface IRouterPattern {
  path: string;
  noAuth?: boolean;
  pageComponent: string;
  isExact?: boolean;
  layout?: FC;
}

let transactionRoutes: IRouterPattern[] = [];
if (Localization.activateTransactionListing) {
  transactionRoutes = [
    {
      path: '/transaction/:type/:id',
      pageComponent: 'Transaction/detail',
      layout: DashboardTemplate,
    },
    {
      path: '/transactions',
      pageComponent: 'Transaction/list',
      layout: DashboardTemplate,
    },
  ];
}

let memoRoutes: IRouterPattern[] = [];
if (Localization.activateCreditDebitMemo) {
  memoRoutes = [
    {
      path: '/record-memo/:id',
      pageComponent: 'Memo/record-memo',
      layout: DashboardTemplate,
    },
  ];
}

const loginRoute: IRouterPattern[] = [
  {
    path: '/login',
    noAuth: true,
    pageComponent: 'Login',
  },
  {
    path: '/borrower/login',
    noAuth: true,
    pageComponent: 'Login',
  },
  {
    path: '/forgot-password',
    noAuth: true,
    pageComponent: 'Login/forgot-password',
  },
  {
    path: '/reset-password',
    noAuth: false,
    pageComponent: 'Login/reset-password',
  },
  {
    path: '/reset-password-success',
    noAuth: true,
    pageComponent: 'Login/reset-password-success',
  },
];

const dashboardRoutes: IRouterPattern[] = [
  {
    path: '/dashboard',
    pageComponent: 'Dashboard',
    layout: DashboardTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    layout: DashboardTemplate,
  },
];

const superuserRoutes: IRouterPattern[] = [
  {
    path: '/broadcast/add',
    pageComponent: 'Superuser/broadcast-add',
    layout: SuperuserTemplate,
  },
  {
    path: '/broadcast/view',
    pageComponent: 'Superuser/broadcast-view',
    layout: SuperuserTemplate,
  },
  {
    path: '/broadcast/detail/:id',
    pageComponent: 'Superuser/broadcast-detail',
    layout: SuperuserTemplate,
  },
];

const dealerRoutes: IRouterPattern[] = [
  {
    path: '/active/:id',
    pageComponent: 'Dealer/account-detail',
    layout: DealerTemplate,
  },
  {
    path: '/matured/:id',
    pageComponent: 'Dealer/account-detail',
    layout: DealerTemplate,
  },
  {
    path: '/active',
    pageComponent: 'Dashboard',
    layout: DealerTemplate,
  },
  {
    path: '/matured',
    pageComponent: 'Dashboard',
    layout: DealerTemplate,
  },
  {
    path: '/record-payment/:id',
    pageComponent: 'Payment/record-payment',
    layout: DashboardTemplate,
  },
];

const borrowerRoutes: IRouterPattern[] = [
  {
    path: '/detail/:id',
    pageComponent: 'Customer/detail',
    layout: CustomerTemplate,
  },
];

const cashierRoutes: IRouterPattern[] = [
  {
    path: '/record-payment/:id',
    pageComponent: 'Payment/record-payment',
    layout: DashboardTemplate,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const adminRoutes: IRouterPattern[] = [
  {
    path: '/version',
    noAuth: true,
    pageComponent: 'Version',
  },
  {
    path: '/collection-detail/:id',
    pageComponent: 'Collection/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist-detail/:id',
    pageComponent: 'Specialist/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll-list',
    pageComponent: 'ScrollListing/list',
    layout: DashboardTemplate,
  },
  {
    path: '/user-list',
    pageComponent: 'UserList/list',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll-details/:id',
    pageComponent: 'Scroll/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll-generate/:id',
    pageComponent: 'Scroll/create',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll-update/:id',
    pageComponent: 'Scroll/update',
    layout: DashboardTemplate,
  },
  {
    path: '/repo-details/:id',
    pageComponent: 'Collection/repo-details',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist-itinerary',
    pageComponent: 'Specialist/itinerary',
    layout: DashboardTemplate,
  },
  {
    path: '/specialist',
    pageComponent: 'Specialist/list',
    layout: DashboardTemplate,
  },
  {
    path: '/skipped-visit',
    pageComponent: 'Collection/skip-visit',
    layout: DashboardTemplate,
  },
  {
    path: '/cashier-detail/:id',
    pageComponent: 'Cashier/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/cashiers',
    pageComponent: 'Cashier/list',
    layout: DashboardTemplate,
  },
  {
    path: '/branches',
    pageComponent: 'Branch/list',
    layout: DashboardTemplate,
  },
  {
    path: '/scroll',
    pageComponent: 'Scroll/list',
    layout: DashboardTemplate,
  },
  {
    path: '/cs-itinerary',
    pageComponent: 'CsItinerary',
    layout: DashboardTemplate,
  },
  {
    path: '/accounts',
    pageComponent: 'Account/list',
    layout: DashboardTemplate,
  },
  {
    path: '/account/:id',
    pageComponent: 'Account/detail',
    layout: DashboardTemplate,
  },
  {
    path: '/',
    pageComponent: 'Dashboard',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/task',
    pageComponent: 'Task/list',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manual-allocations/history',
    pageComponent: 'Allocations/allocationHistory',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations',
    pageComponent: 'Allocations/manualAllocations',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations/new-allocations',
    pageComponent: 'Allocations/newAllocation',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations/review/:allocationId',
    pageComponent: 'Allocations/newAllocationReview',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations/upload/:type',
    pageComponent: 'Allocations/bulkUpload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations/:type/upload',
    pageComponent: 'Allocations/bulkUploadAdmin',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manual-allocations/list',
    pageComponent: 'Allocations/allocationList',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/daily-collections',
    pageComponent: 'DailyCollection/list',
    layout: DashboardTemplate,
  },
  {
    path: '/reports-downloads',
    pageComponent: 'DailyCollection/reports',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-clients',
    pageComponent: 'Administration/Clients/clients',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-clients/detail',
    pageComponent: 'Administration/Clients/Detail/DetailClients',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-clients/detail/:clientId',
    pageComponent: 'Administration/Clients/Detail/DetailClients',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-roles',
    pageComponent: 'Administration/Roles/roles',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-roles/edit-role/:roleId',
    pageComponent: 'Administration/Roles/EditRoles',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/activity-log',
    pageComponent: 'Administration/ActivityLog/index',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-books',
    pageComponent: 'Administration/Books/ListBooks',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-workflow',
    pageComponent: 'Administration/Workflow/ListWorkflows',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-workflow/add',
    pageComponent: 'Administration/Workflow/AddWorkflows',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-workflow/:workflow_id',
    pageComponent: 'Administration/Workflow/AddWorkflows',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-books/edit-book',
    pageComponent: 'Administration/Books/ActionBooks/EditBooks',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-books/edit-book/:bookId',
    pageComponent: 'Administration/Books/ActionBooks/EditBooks',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-books/upload',
    pageComponent: 'Administration/Books/Upload/upload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-books/history',
    pageComponent: 'Administration/Books/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-roles/collector_listings',
    pageComponent: 'Administration/Roles/collector',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-roles/user/:id',
    pageComponent: 'Administration/Roles/collector',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-users',
    pageComponent: 'Administration/Users/List/users',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-users/actions',
    pageComponent: 'Administration/Users/ActionUser',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-users/history',
    pageComponent: 'Administration/Users/History/history',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-users/upload',
    pageComponent: 'Administration/Users/Upload/upload',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-users/uploadcoh',
    pageComponent: 'Administration/Users/Upload/uploadcoh',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-users/caplimit',
    pageComponent: 'Administration/Users/Upload/uploadcap',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-org-structure',
    pageComponent: 'Administration/OrgStructure',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts',
    pageComponent: 'Administration/Accounts/List/accounts',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/list-users',
    pageComponent: 'Administration/Accounts/List/AccountListView',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/upload-monthly/:id',
    pageComponent: 'Administration/Accounts/Upload/uploadMonthly',
    // isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/upload-reassignment/:id',
    pageComponent: 'Administration/Accounts/Upload/uploadReassignment',
    // isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/history',
    pageComponent: 'Administration/Accounts/History/history',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/history/:id',
    pageComponent: 'Administration/Accounts/History/historyDetail',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-accounts/actions-account',
    pageComponent: 'Administration/Accounts/List/actionsAccount',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-tasks',
    pageComponent: 'Administration/Tasks/List/tasks',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-allocations',
    pageComponent: 'Administration/Allocations/allocations',
    layout: DashboardTemplate,
  },
  {
    path: '/manage-banks',
    pageComponent: 'Administration/Banks/ListBank',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-banks/edit-bank',
    pageComponent: 'Administration/Banks/ActionsBank/EditBank',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-banks/edit-bank/:bankId',
    pageComponent: 'Administration/Banks/ActionsBank/EditBank',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-banks/upload',
    pageComponent: 'Administration/Banks/Upload/upload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-banks/history',
    pageComponent: 'Administration/Banks/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/Manage-COH-Reasons',
    pageComponent: 'Administration/COHReasons/COHReasons',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/Manage-COH-Reasons/add-reasons',
    pageComponent: 'Administration/COHReasons/ActionReasons/actionReasons',
    layout: DashboardTemplate,
  },
  {
    path: '/Manage-COH-Reasons/edit-reasons/:reasonsId',
    pageComponent: 'Administration/COHReasons/ActionReasons/actionReasons',
    layout: DashboardTemplate,
  },

  {
    path: '/manage-portfolio',
    pageComponent: 'Administration/Portfolio/ListPortfolio',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-portfolio/create-portfolio',
    pageComponent: 'Administration/Portfolio/Actions/EditPortfolio',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-portfolio/edit-portfolio/:portfolioId',
    pageComponent: 'Administration/Portfolio/Actions/EditPortfolio',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-coh-multiplier/edit-coh-multiplier/',
    pageComponent: 'Administration/CohMultiplier/Actions/EditCohMultiplier',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-agency/create-agency',
    pageComponent: 'Administration/Agency/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency/edit-agency/:agencyId',
    pageComponent: 'Administration/Agency/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency/edit-agency/:agencyId',
    pageComponent: 'Administration/Agency/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agencies/upload',
    pageComponent: 'Administration/Agency/Upload/Upload',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency/upload',
    pageComponent: 'Administration/Agency/Upload/Upload',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agencies',
    pageComponent: 'Administration/Agency/ListAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency-user/create-agency',
    pageComponent: 'Administration/AgencyUser/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency-user/history',
    pageComponent: 'Administration/AgencyUser/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-agency-user/edit-agency/:agencyId',
    pageComponent: 'Administration/AgencyUser/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency-user/edit-agency/:agencyId',
    pageComponent: 'Administration/AgencyUser/Actions/EditAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency-user/upload',
    pageComponent: 'Administration/AgencyUser/Upload/Upload',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-agency-user',
    pageComponent: 'Administration/AgencyUser/ListAgency',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-yards',
    pageComponent: 'Administration/Yards/ListYard',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-yards/edit-yard',
    pageComponent: 'Administration/Yards/ActionYards/ActionYards',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-yards/approve-yard/:yardId',
    pageComponent: 'Administration/Yards/ActionYards/ApproveYards',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-yards/edit-yard/:yardId',
    pageComponent: 'Administration/Yards/ActionYards/ActionYards',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-yards/upload',
    pageComponent: 'Administration/Yards/Upload/upload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-yards/history',
    pageComponent: 'Administration/Yards/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-incentives',
    pageComponent: 'Administration/Incentives/list',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-incentives/add-incentive',
    pageComponent: 'Administration/Incentives/action',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-incentives/add-incentive/:id',
    pageComponent: 'Administration/Incentives/action',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-incentives/add-incentive/:id/:action',
    pageComponent: 'Administration/Incentives/action',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-incentives/edit-incentive',
    pageComponent: 'Administration/Incentives/edit',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-branches',
    pageComponent: 'Administration/Branches/ListCompanyBranche',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-branches/edit-branch',
    pageComponent: 'Administration/Branches/Detail/DetailBranch',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-branches/edit-branch/:branchId',
    pageComponent: 'Administration/Branches/Detail/DetailBranch',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-branches/upload',
    pageComponent: 'Administration/Branches/Upload/upload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-branches/history',
    pageComponent: 'Administration/Branches/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  // Yarn -> ManageBidders
  {
    path: '/manage-bidders',
    pageComponent: 'Yard/ManageBidders/ListManageBidders',
    layout: DashboardTemplate,
    isExact: true,
  },
  // Yarn -> BiddingList
  {
    path: '/bidding-list',
    pageComponent: 'Yard/BiddingList/ListManageBidders',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-bidders/detail-bidder/:bidderId',
    pageComponent: 'Yard/ManageBidders/AddUser/AddUser',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-bidders/add-user',
    pageComponent: 'Yard/ManageBidders/AddUser/AddUser',
    isExact: true,
    layout: DashboardTemplate,
  },
  {
    path: '/manage-bidders/add-user/:bidderId',
    pageComponent: 'Yard/ManageBidders/AddUser/AddUser',
    isExact: true,
    layout: DashboardTemplate,
  },

  // Yarn -> RepoAccount
  {
    path: '/repo-accounts',
    pageComponent: 'Yard/RepoAccount/ListManageBidders',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-assets',
    pageComponent: 'Administration/Assets/Asset',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-assets/edit-assets',
    pageComponent: 'Administration/Assets/ActionAsset/ActionAsset',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-assets/edit-assets/:assetsId',
    pageComponent: 'Administration/Assets/ActionAsset/ActionAsset',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-assets/upload',
    pageComponent: 'Administration/Assets/Upload/upload',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-assets/history',
    pageComponent: 'Administration/Assets/History/history',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/notice-issuance/:id',
    pageComponent: 'Legal/NoticeIssuance/detail',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/notice-issuance',
    pageComponent: 'Legal/NoticeIssuance/list',
    layout: DashboardTemplate,
    isExact: true,
  },

  {
    path: '/manage-metrics',
    pageComponent: 'Administration/Metrics/List/list',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-metrics/metric/:action',
    pageComponent: 'Administration/Metrics/Detail/detail',
    layout: DashboardTemplate,
    isExact: true,
  },
  {
    path: '/manage-metrics/metric/:id/:action',
    pageComponent: 'Administration/Metrics/Detail/detail',
    layout: DashboardTemplate,
    isExact: true,
  },
  ...memoRoutes,
  ...transactionRoutes,
];

const rpaRoutes: IRouterPattern[] = [
  {
    path: '/',
    pageComponent: 'Rpa',
  },
];

const receiptRoutes: IRouterPattern[] = [
  {
    path: '/r/:id',
    pageComponent: 'Receipt',
    noAuth: true,
  },
];

const getRoutes = (role: string) => {
  switch (role) {
    case 'R':
      return [...loginRoute, ...rpaRoutes];
    case 'A':
      return [...loginRoute, ...cashierRoutes, ...dashboardRoutes];
    case 'C':
    case 'M':
      return [...loginRoute, ...adminRoutes, ...dashboardRoutes];
    case 'D':
      return [
        ...dealerRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: DealerTemplate,
        })),
      ];
    case 'B':
      return [
        ...borrowerRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: CustomerTemplate,
        })),
      ];
    case 'S':
      return [
        ...superuserRoutes,
        ...loginRoute,
        ...dashboardRoutes.map((item: IRouterPattern) => ({
          ...item,
          layout: SuperuserTemplate,
        })),
      ];
    default:
      return [...loginRoute, ...receiptRoutes, ...dashboardRoutes, ...cashierRoutes];
  }
};

export default getRoutes;
