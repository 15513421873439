/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import {
  BORROWER_ROLE,
  CASHIER_ROLE,
  CORPORATE_ROLE,
  DEALER_ROLE,
  SUPERUSER_ROLE,
  Localization,
  MANAGER_ROLE,
} from 'constant';
import { userLoad, userLogout } from 'redux/auth/actions';
import authProvider from 'utils/authProvider';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import { AppContext, isSideBarMenuUsers } from 'helpers';
import { element } from 'prop-types';

const incentiveViewRoles = ['RCM', 'NCM'];

const Sidebar = ({ size, setShowSidebar }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const {
    showListingsSubmenu,
    setShowListingsSubmenu,
    showAllocationsSubmenu,
    setShowAllocationsSubmenu,
    showReportsSubmenu,
    setShowReportsSubmenu,
    showCollectionsSubmenu,
    setShowCollectionsSubmenu,
    showAdministrationSubmenu,
    setShowAdministrationSubmenu,
    showLegalSubmenu,
    setShowLegalSubmenu,
  } = useContext(AppContext);
  // Submenu toggle handler
  const toggleSubMenu = (event) => {
    switch (event?.name) {
      case 'Collections':
        setShowCollectionsSubmenu(!showCollectionsSubmenu);
        break;
      case 'Listings':
        setShowListingsSubmenu(!showListingsSubmenu);
        break;
      case 'Allocations':
        setShowAllocationsSubmenu(!showAllocationsSubmenu);
        break;
      case 'Reports':
        setShowReportsSubmenu(!showReportsSubmenu);
        break;
      case 'Administration':
        setShowAdministrationSubmenu(!showAdministrationSubmenu);
        break;
      case 'Legal':
        setShowLegalSubmenu(!showLegalSubmenu);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    authProvider.logout();
    dispatch(clearActionStatus('userTc'));

    // clear user info and reset notifications
    dispatch(userLogout());
    dispatch(
      fetchCollection('notifications', 'cms/non-sensitive-notifications', {
        filter: {
          client: Localization.clientName,
        },
      }),
    );
    history.push('/login');
  };

  const menus = (() => {
    const items = [];
    const sspItems = [];
    const legalItems = [];
    switch (auth?.user?.role) {
      case SUPERUSER_ROLE:
        return [
          {
            name: 'Broadcast',
            items: [
              {
                name: 'Add message',
                href: '/broadcast/add',
                link: '/broadcast/add',
              },
              {
                name: 'View messages',
                href: '/broadcast/view',
                link: '/broadcast/view',
              },
            ],
          },
        ];
      case DEALER_ROLE:
        return [
          {
            name: 'Accounts',
            icon: 'user.png',
            items: [
              {
                name: 'Active',
                href: '/accounts/active',
                link: '/active',
              },
              {
                name: 'Matured',
                href: '/accounts/matured',
                link: '/matured',
              },
            ],
          },
        ];
      case CASHIER_ROLE:
        return [
          {
            name: t('Accounts'),
            href: '/dashboard',
            link: '/dashboard',
          },
          {
            name: t('Records'),
            href: '/transactions',
            link: '/transactions',
          },
        ];
      case MANAGER_ROLE:
        items.push({
          name: t(Localization.branch),
          href: '/branches',
          link: '/branches',
        });
        items.push({
          name: t(Localization.collectionSpecialist),
          href: '/specialist',
          link: '/specialist',
        });
        items.push({
          name: t('Scroll'),
          href: '/scroll-list',
          link: '/scroll-list',
        });
        items.push({
          name: 'Accounts', // t('Daily Collections'),
          href: '/manage-accounts',
          link: '/manage-accounts',
        });
        // items.push({
        //   name: t('Users'),
        //   href: '/user-list',
        //   link: '/user-list',
        // });
        if (Localization.showCashierPage) {
          items.push({
            name: 'Cashiers', // t('Cashiers'), Removed translation because submenu toggling logic uses name and translation can break it
            href: '/cashiers',
            link: '/cashiers',
          });
        }
        if (auth?.user?.manager_type == 'AUTO') {
          sspItems.push(
            {
              name: 'Manage Users', // t('Daily Collections'),
              href: '/manage-users',
              link: '/manage-users',
            },
            // {
            //   name: 'Manage Clients', // t('Daily Collections'),
            //   href: '/manage-clients',
            //   link: '/manage-clients',
            // },
            // {
            //   name: 'Manage Roles', // t('Daily Collections'),
            //   href: '/manage-roles',
            //   link: '/manage-roles',
            // },
            // {
            //   name: 'Manage Org Structure', // t('Daily Collections'),
            //   href: '/manage-org-structure',
            //   link: '/manage-org-structure',
            // },
            {
              name: 'Manage Banks', // t('Daily Collections'),
              href: '/manage-banks',
              link: '/manage-banks',
            },
            // {
            //   name: 'Manage Tasks', // t('Daily Collections'),
            //   href: '/manage-tasks',
            //   link: '/manage-tasks',
            // },
            {
              name: 'Manage Books', // t('Daily Collections'),
              href: '/manage-books',
              link: '/manage-books',
            },
            {
              name: 'Manage COH Reasons', // t('Daily Collections'),
              href: '/Manage-COH-Reasons',
              link: '/Manage-COH-Reasons',
            },
            // {
            //   name: 'Manage Bidders', // t('Daily Collections'),
            //   href: '/manage-bidders',
            //   link: '/manage-bidders',
            // },
            {
              name: 'Manage Agency', // t('Daily Collections'),
              href: '/manage-agencies',
              link: '/manage-agencies',
            },
            {
              name: 'Manage Agency User',
              href: '/manage-agency-user',
              link: '/manage-agency-user',
            },
            {
              name: 'Manage Portfolio', // t('Daily Collections'),
              href: '/manage-portfolio',
              link: '/manage-portfolio',
            },
            {
              name: 'Manage Coh Multiplier', // t('Daily Collections'),
              href: '/manage-coh-multiplier/edit-coh-multiplier/',
              link: '/manage-coh-multiplier/edit-coh-multiplier/',
            },
            {
              name: 'Manage Asset', // t('Daily Collections'),
              href: '/manage-assets',
              link: '/manage-assets',
            },
            // {
            //   name: 'Manage Yards', // t('Daily Collections'),
            //   href: '/manage-yards',
            //   link: '/manage-yards',
            // },
          );
        } else if (auth?.settings?.show_incentive_program && incentiveViewRoles.includes(auth?.user?.manager_type)) {
          sspItems.push({
            name: 'Manage Incentives', // t('Daily Collections'),
            href: '/manage-incentives',
            link: '/manage-incentives',
          });
        } else {
          sspItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }

        if (auth?.user?.manager_type != 'AGY' && auth?.settings?.show_legal_request) {
          legalItems.push(
            {
              name: 'Notice Issuance',
              href: '/notice-issuance',
              link: '/notice-issuance',
            },
            // {
            //   name: 'Legal Visit',
            //   href: '/legal-visit',
            //   link: '/legal-visit',
            // },
          );
        } else if (auth?.user?.manager_type === 'CA') {
          return [
            {
              name: 'Dashboard', // t('Dashboard'),
              href: '/dashboard',
              link: '/dashboard',
              icon: 'dashboard.png',
            },
            {
              name: 'Tasks', // t('Dashboard'),
              href: '/task',
              link: '/task',
              icon: 'clock.png',
            },
            {
              name: 'Allocations',
              icon: 'allocations.png',
              items: [
                {
                  name: 'Manual Allocations',
                  href: '/manual-allocations',
                  link: '/manual-allocations',
                },
              ],
            },
          ];
        } else {
          legalItems.push({
            name: 'No Access',
            href: '',
            link: '',
          });
        }

        return [
          {
            name: 'Dashboard', // t('Dashboard'),
            href: '/dashboard',
            link: '/dashboard',
            icon: 'dashboard.png',
          },
          {
            name: 'Collections', // t('Collections'),
            icon: 'cashacct.png',
            items: [
              {
                name: 'Scroll',
                href: '/scroll',
                link: '/scroll',
              },
              {
                name: 'Accounts', // t('Accounts'),
                href: '/accounts',
                link: '/accounts',
              },
            ],
          },
          {
            name: 'Tasks', // t('Dashboard'),
            href: '/task',
            link: '/task',
            icon: 'clock.png',
          },
          !['AUTO', 'OPS', 'TCM'].includes(auth?.user?.manager_type)
            ? {
                name: 'Allocations',
                icon: 'allocations.png',
                items: [
                  {
                    name: 'Manual Allocations',
                    href: '/manual-allocations',
                    link: '/manual-allocations',
                  },
                ],
              }
            : undefined,
          {
            name: 'Listings', // t('Listings'),
            icon: 'listings.png',
            items,
          },
          {
            name: 'Reports', // t('Reports'),
            icon: 'icon_report.png',
            items: [
              {
                name: 'Daily Collections', // t('Daily Collections'),
                href: '/daily-collections',
                link: '/daily-collections',
              },
              {
                name: 'Downloads', // t('Downloads'),
                href: '/reports-downloads',
                link: '/reports-downloads',
              },
            ],
          },
          {
            name: 'Administration', // t('Administration'),
            icon: 'icon_administration.svg',
            items: sspItems,
          },
          {
            name: 'Legal',
            icon: 'icon_legal.svg',
            items: legalItems,
          },
          // {
          //   name: 'Task',
          //   link: '/manage-tasks',
          //   icon: 'clock.png',
          // },
        ];
      default:
        return [];
    }
  })();

  const renderNavMenu = (el: any) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {el?.icon && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <img
              className="align-self-center"
              src={`${process.env.PUBLIC_URL}/images/${el.icon}`}
              alt="icon"
              width="28"
              height="28"
              onClick={() => toggleSubMenu(el)}
            />
          )}
          {!el?.link ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className="ml-3 align-self-center"
              onClick={() => {
                toggleSubMenu(el);
              }}
              aria-hidden="true"
            >
              {el.name}
            </div>
          ) : (
            <Button
              color="text"
              onClick={(event) => {
                event.stopPropagation();
                if (['md', 's', 'xs'].includes(size.screen)) setShowSidebar(false);
                history.push(el.link);
              }}
            >
              {el.name}
            </Button>
          )}
        </div>
        {renderArrow(el, el?.items, el?.name)}
      </div>
    );
  };

  const renderArrow = (el, items, name) => {
    if (items) {
      if (
        (name === 'Collections' && showCollectionsSubmenu) ||
        (name === 'Listings' && showListingsSubmenu) ||
        (name === 'Reports' && showReportsSubmenu) ||
        (name === 'Administration' && showAdministrationSubmenu) ||
        (name === 'Legal' && showLegalSubmenu)
      ) {
        return (
          <div className="table-arrow-up align-self-center" onClick={() => toggleSubMenu(el)} aria-hidden="true" />
        );
      }
      return (
        <div className="table-arrow-down align-self-center" onClick={() => toggleSubMenu(el)} aria-hidden="true" />
      );
    }
    return <></>;
  };

  const route = useRouteMatch();

  const activeRoute = route.path === '/' ? '/dashboard' : route.path;
  return (
    <SidebarComponent className="p-0 d-flex flex-column justify-content-between">
      <div style={{ textAlign: 'center', overflowY: 'scroll' }}>
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
            alt={Localization.clientName}
            // height="45"
            width="80%"
            className="mt-3"
          />
        </Link>
        <ListGroupStyle>
          {menus.map(
            (el: any) =>
              el && (
                <>
                  <ListGroupItem
                    tag={el.href ? 'a' : 'div'}
                    href={el?.href}
                    key={el.name}
                    className={`parent ${activeRoute.includes(el.link) ? 'is-active' : ''}`}
                  >
                    {renderNavMenu(el)}
                    {el?.items &&
                      ((el?.name === 'Listings' && showListingsSubmenu) ||
                        (el?.name === 'Allocations' && showAllocationsSubmenu) ||
                        (el?.name === 'Collections' && showCollectionsSubmenu) ||
                        (el?.name === 'Reports' && showReportsSubmenu) ||
                        (el?.name === 'Administration' && showAdministrationSubmenu) ||
                        (el?.name === 'Legal' && showLegalSubmenu)) && (
                        <ListGroupStyle>
                          {el?.items?.map((child: any, index: number) => (
                            <ListGroupItem
                              key={child.href}
                              className={`sub ${activeRoute.includes(child.link) ? 'is-active' : ''}`}
                            >
                              <ul style={{ paddingLeft: '20px' }}>
                                <li
                                  style={{
                                    paddingTop: '12px',
                                    paddingBottom: '12px',
                                    listStyle: 'disc',
                                    color: '#999999',
                                  }}
                                >
                                  {renderNavMenu(child)}
                                </li>
                              </ul>
                            </ListGroupItem>
                          ))}
                        </ListGroupStyle>
                        // eslint-disable-next-line @typescript-eslint/indent
                      )}
                  </ListGroupItem>
                </>
              ),
          )}
        </ListGroupStyle>
      </div>
      <ListGroupStyle>
        <div>
          <div className="d-flex justify-content-center mb-2">
            <FooterText className="mr-2">Powered by</FooterText>
            <img
              className="d-flex align-self-start"
              src={`${process.env.PUBLIC_URL}/images/TVSDigital_logo_72x26.svg`}
              alt="TVS Digital"
            />
          </div>
        </div>
        <Hrline />
        <div className="d-flex justify-content-around mb-3 ml-3 mr-3">
          <LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </LegalLink>
          <LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
            Terms of use
          </LegalLink>
          <LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
            Cookie Policy
          </LegalLink>
        </div>
      </ListGroupStyle>
    </SidebarComponent>
  );
};
const Hrline = styled.hr`
  border: 0;
  border-bottom: 1px solid #dddddd;
  display: block;
  width: 90%;
`;
const SidebarComponent = styled.div`
  box-shadow: 0;
  background-color: #fff;
  width: 250px;
  position: fixed;
  height: calc(100vh - 50px);
  @media (max-width: 820px) {
    position: static;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const ListGroupStyle = styled(ListGroup)`
  margin-top: 20px;
  .list-group {
    margin: 0;
  }
  .list-group-item {
    background-color: transparent;
    padding: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid transparent;
    margin-left: -1px;
    .btn {
      font-weight: 500;
      color: #333333;
    }
    &.sub {
      padding-left: 27px;
      .btn {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      &.is-active {
        a,
        .btn {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
    > div {
      padding: 15px;
      font-weight: 500;
      color: #333333;
      align-self: center;
    }
    &.parent.is-active {
      > div {
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
  }
`;
const FooterText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
`;
const LegalLink = styled.a`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;
export default Sidebar;
